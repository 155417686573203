import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpdatePurchaseOrdersModule } from './admin/purchaseOrders/update-purchase-orders/update-purchase-orders.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US, zh_HK } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { StateService } from './services/state.service';
import { WebService } from './services/web.service';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NgChartsModule } from 'ng2-charts';
// registerLocaleData(en);
registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzFormModule,
    NzButtonModule,
    NzMessageModule,
    NzToolTipModule,
    NzBreadCrumbModule,
    NzDescriptionsModule,
    NzImageModule,
    NzSegmentedModule,
    UpdatePurchaseOrdersModule,
    NgChartsModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_HK },
    StateService,
    WebService,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
