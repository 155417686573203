import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from '../models/category';
import { Brand } from '../models/brand';
import { Product } from '../models/product';
import { Supplier } from '../models/supplier';
import { COUNTRY_MAP, SUPPLIER_TYPE_MAP, SUPPLIER_REGION_MAP, SUPPLIER_STATUS_MAP, BRAND_STATUS_MAP, LOCATION_STATUS_MAP, USER_STATUS_MAP, LOCATION_TYPES_MAP } from '../utils/constants';
import { WebService } from './web.service';
import CascaderOption from '../models/cascaderOption';
import { Country } from '../models/country';
import { User } from '../models/user';
import { Location } from '../models/location';
import { formatISODateToYYYYMMDD } from '../utils/utils';
import { ShopifyProduct } from '../models/shopify_product';
import { Role } from '../models/role';
import { ProductPriceChange } from '../models/product-price-change';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  products: Product[] = [];
  productNames: string[] = [];
  productBarcodes: string[] = [];

  suppliers: Supplier[] = [];
  supplierNamesEn: string[] = [];
  supplierNamesZh: string[] = [];
  supplierCompanyNamesEn: string[] = [];
  supplierCompanyNamesZh: string[] = [];

  categories: Category[] = [];
  firstLevelCategoryNames: string[] = [];
  secondLevelCategoryNames: string[] = [];
  thirdLevelCategoryNames: string[] = [];
  distributionCategoryNames: string[] = [];
  categoryOptions: CascaderOption[] = [];

  brands: Brand[] = [];
  brandNames: string[] = [];

  users: User[] = [];
  userNames: string[] = [];

  roles: Role[] = [];

  countries: Country[] = [];
  countryIdMap = new Map<number, string>();
  countryNameMap = new Map<string, number>();
  countryNameZhMap = new Map<string, number>();
  countryOptions = new Map<number, string>();

  locations: Location[] = [];
  locationEnNames: string[] = [];
  locationZhNames: string[] = [];
  locationCodes: string[] = [];
  activeStores: Location[] = [];
  activeLocations: Location[] = [];
  warehouseLocation: Location | undefined;

  productPriceChanges: ProductPriceChange[] = [];

  private shopifyProductsCache: Map<string, {
    data: ShopifyProduct[],
    timestamp: number
  }> = new Map();

  constructor(
    private webService: WebService,
    private router: Router
  ) {

  }

  async loadAll() {
    if (this.countries.length === 0) {
      await this.allCountries(true);
    }
    if (this.users.length === 0) {
      await this.allUsers(true);
    }
    if (this.suppliers.length === 0) {
      this.allSuppliers(true);
    }
    if (this.categories.length === 0) {
      this.allCategories(true);
    }
    if (this.brands.length === 0) {
      this.allBrands(true);
    }
    // if (this.products.length === 0) {
    //   this.allProducts(true);
    // }
  }

  async allProducts(reload = false): Promise<Product[]> {
    if (this.products.length === 0 || reload) {
      await this.webService.getProductList().then((res) => {
        if (res.status >= 200 && res.status < 299) {
          this.products = res.body.filter((product: any) => product.status !== 'deleted').map((product: any) => {
            return <Product><unknown>{
              id: product.id,
              name: product.name,
              description: product.description,
              status: product.status,
              handle: product.handle,
              barcode: product.barcode,
              cost: product.cost,
              price: product.price,
              compare_at_price: product.compare_at_price,
              content: product.content,
              content_unit: product.content_unit,
              category_id: product.category_id,
              first_level_name: product.category_id?.first_level_name,
              second_level_name: product.category_id?.second_level_name,
              third_level_name: product.category_id?.third_level_name,
              brand_name: product.brand_id?.name ?? '',
              brand_id: product.brand_id?.id ?? '',
              country_name: product.country_id?.name_zh,
              country_id: product.country_id?.id,
              buyer_id: product.buyer_id.name,
              storage_condition: product.storage_condition,
              shelf_life: product.shelf_life,
              shelf_life_unit: product.shelf_life_unit,
              created_by: product.created_by,
              created_at: product.created_at.split('T')[0],
              updated_at: product.updated_at.split('T')[0],
              updated_by: product.updated_by,
              shopify_id: product.shopify_id,
            };
          });
          this.productNames = this.products.map(product => product.name);
          this.productBarcodes = this.products.map(product => product.barcode);
        } else {
          console.error('Failed to fetch products', res.status);
          this.products = [];
        }
      })
    }
    return this.products;
  }

  // productNameExists(name: string): boolean {
  //   return this.productNames.includes(name);
  // }

  // productBarcodeExists(barcode: string): boolean {
  //   return this.productBarcodes.includes(barcode);
  // }

  async allSuppliers(reload = false): Promise<Supplier[]> {
    if (this.suppliers.length === 0 || reload) {
      try {
        const res = await this.webService.getAllSuppliers();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.suppliers = (res.body as any[]).map((supplier: any) => {
            return <Supplier>{
              id: supplier.id,
              name_en: supplier.name_en,
              name_zh: supplier.name_zh,
              company_name_en: supplier.company_name_en,
              company_name_zh: supplier.company_name_zh,
              bank_name: supplier.bank_name,
              bank_code: supplier.bank_code,
              type: SUPPLIER_TYPE_MAP[supplier.type],
              product_type: supplier.product_type,
              phone_no: supplier.phone_no,
              contact_person: supplier.contact_person,
              email: supplier.email,
              wechat_id: supplier.wechat_id,
              line_id: supplier.line_id,
              address: supplier.address,
              website: supplier.website,
              region: SUPPLIER_REGION_MAP[supplier.region],
              remarks: supplier.remarks,
              created_at: supplier.created_at,
              created_by: supplier.created_by,
              status: SUPPLIER_STATUS_MAP[supplier.status],
              buyer_id: supplier.buyer_id.id,
              buyer_name: supplier.buyer_id.name,
              last_purchase_date: supplier.last_purchase_date,
            };
          });
          this.supplierNamesEn = this.suppliers.map(supplier => supplier.name_en);
          this.supplierNamesZh = this.suppliers.map(supplier => supplier.name_zh);
          this.supplierCompanyNamesEn = this.suppliers.map(supplier => supplier.company_name_en);
          this.supplierCompanyNamesZh = this.suppliers.map(supplier => supplier.company_name_zh);
        } else {
          console.error('Failed to fetch suppliers', res?.status);
          this.suppliers = [];
        }
      } catch (error) {
        console.error('Error fetching suppliers', error);
        this.suppliers = [];
      }
    }
    return this.suppliers;
  }

  // supplierNameExistsEn(name: string): boolean {
  //   return this.supplierNamesEn.includes(name);
  // }

  // supplierNameExistsZh(name: string): boolean {
  //   return this.supplierNamesZh.includes(name);
  // }

  // supplierCompanyNameExistsEn(name: string): boolean {
  //   return this.supplierCompanyNamesEn.includes(name);
  // }

  // supplierCompanyNameExistsZh(name: string): boolean {
  //   return this.supplierCompanyNamesZh.includes(name);
  // }

  async allCategories(reload = false): Promise<Category[]> {
    if (this.categories.length === 0 || reload) {
      try {
        const res = await this.webService.getAllCategories();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.categories = (res.body as any[]).map((category: any) => {
            return <Category><unknown>{
              id: category.id,
              first_level_name: category.first_level_name,
              second_level_name: category.second_level_name,
              third_level_name: category.third_level_name,
              first_level_code: category.first_level_code,
              second_level_code: category.second_level_code,
              third_level_code: category.third_level_code,
              distribution_level_name: category.distribution_level_name,
              distribution_level_code: category.distribution_level_code,
              target_sku: category.target_sku,
              created_at: category.created_at,
              created_by: category.created_by,
              updated_at: category.updated_at,
              updated_by: category.updated_by,
            };
          });
          this.firstLevelCategoryNames = Array.from(new Set(this.categories.map(category => category.first_level_name)));
          this.secondLevelCategoryNames = Array.from(new Set(this.categories.map(category => category.second_level_name)));
          this.thirdLevelCategoryNames = Array.from(new Set(this.categories.map(category => category.third_level_name)));
          this.distributionCategoryNames = Array.from(new Set(this.categories.map(category => category.distribution_level_name)));
        } else {
          console.error('Failed to fetch categories', res?.status);
          this.categories = [];
        }
      } catch (error) {
        console.error('Error fetching suppliers', error);
        this.categories = [];
      }
    }
    if (this.categories.length > 0) {
      this.categoryOptions = this.transformCategories(this.categories);
    }
    return this.categories;
  }

  transformCategories(categories): CascaderOption[] {
    const firstLevelMap = new Map<string, CascaderOption>();

    categories.forEach(category => {
      if (!firstLevelMap.has(category.first_level_code)) {
        firstLevelMap.set(category.first_level_code, {
          value: category.first_level_code,
          label: category.first_level_name,
          children: []
        });
      }

      const firstLevel = firstLevelMap.get(category.first_level_code);
      const secondLevelIndex = firstLevel!.children!.findIndex(
        child => child.value === category.second_level_code
      );

      if (secondLevelIndex === -1) {
        firstLevel!.children!.push({
          value: category.second_level_code,
          label: category.second_level_name,
          children: [
            {
              value: category.third_level_code,
              label: category.third_level_name,
              isLeaf: true
            }
          ]
        });
      } else {
        if (firstLevel?.children) {
          firstLevel.children[secondLevelIndex].children!.push({
            value: category.third_level_code,
            label: category.third_level_name,
            isLeaf: true
          });
        }
      }
    });

    return Array.from(firstLevelMap.values());
  }

  firstLevelCategoryNameExists(name: string): boolean {
    return this.firstLevelCategoryNames.includes(name);
  }

  secondLevelCategoryNameExists(name: string): boolean {
    return this.secondLevelCategoryNames.includes(name);
  }

  thirdLevelCategoryNameExists(name: string): boolean {
    return this.thirdLevelCategoryNames.includes(name);
  }

  async allBrands(reload = false): Promise<Brand[]> {
    if (this.brands.length === 0 || reload) {
      try {
        const res = await this.webService.getAllBrands();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.brands = (res.body as any[]).map((brand: any) => {
            return <Brand>{
              id: brand.id,
              name: brand.name,
              shop_photo_urls: brand.files.filter((file: any) => file.file_type === 'shop')?.map((file: any) => file.url),
              logo_urls: brand.files.filter((file: any) => file.file_type === 'logo')?.map((file: any) => file.url),
              shop_name: brand.shop_name,
              website_name: brand.website_name,
              website_url: brand.website_url,
              online_maximum_sales: brand.online_maximum_sales,
              created_by: brand.created_by.name,
              created_at: new Date(brand.created_at).toLocaleDateString().split('T')[0].replaceAll('/', '-'),
              country: COUNTRY_MAP[this.countryIdMap.get(parseInt(brand.country)) ?? ''],
              status: BRAND_STATUS_MAP[brand.status],
              purchase_reason: brand.purchase_reason,
              rank: brand.rank,
              remarks: brand.remarks,
              review_image_urls: brand.files.filter((file: any) => file.file_type === 'review')?.map((file: any) => file.url),
            };
          });
          this.brandNames = this.brands.map(brand => brand.name);
        } else {
          console.error('Failed to fetch brands', res?.status);
          this.brands = [];
        }
      } catch (error) {
        console.error('Error fetching brands', error);
        this.brands = [];
      }
    }
    return this.brands;
  }

  // brandNameExists(name: string): boolean {
  //   return this.brandNames.includes(name);
  // }

  async allUsers(reload = false): Promise<User[]> {
    if (this.users.length === 0 || reload) {
      try {
        const res = await this.webService.getAllUsers();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.users = (res.body as any[]).map((user: any) => {
            return <User><unknown>{
              id: user.id,
              name: user.name,
              username: user.username || '',  // 添加必需屬性
              name_zh: user.name_zh,
              name_en: user.name_en,
              phone_no: user.phone_no,
              email: user.email,
              department: user.role?.department,
              role: user.role?.role_name,
              role_id: user.role_id || null,
              created_at: formatISODateToYYYYMMDD(user.created_at),
              created_by: user.created_by?.name,
              status: USER_STATUS_MAP[user.status],
              locations: user.locations,
              first_level_name: user.first_level_name,
              second_level_name: user.second_level_name,
              third_level_name: user.third_level_name
            };
          });
          this.userNames = this.users.map(user => user.name);
        } else {
          console.error('Failed to fetch users', res?.status);
          this.users = [];
        }
      } catch (error) {
        console.error('Error fetching users', error);
        this.users = [];
      }
    }
    return this.users;
  }

  async allRoles(reload = false): Promise<Role[]> {
    if (this.roles.length === 0 || reload) {
      try {
        const res = await this.webService.getAllRoles();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.roles = res.body.map((role: any) => {
            return <Role>{
              id: role.id,
              role_name: role.role_name,
              department: role.department,
            };
          });
        } else {
          console.error('Failed to fetch roles', res?.status);
          this.roles = [];
        }
      } catch (error) {
        console.error('Error fetching roles', error);
        this.roles = [];
      }
    }
    return this.roles;
  }

  async allCountries(reload = false): Promise<Country[]> {
    if (this.countries.length === 0 || reload) {
      try {
        const res = await this.webService.getAllCountries();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.countries = (res.body as any[]).map((country: any) => {
            return <Country>{
              id: country.id,
              name_en: country.name_en,
              name_zh: country.name_zh,
              code: country.code,
            };
          });
        } else {
          console.error('Failed to fetch countries');
          this.countries = [];
        }
      } catch (error) {
        console.error('Error fetching countries', error);
        this.countries = [];
      }
    }
    if (this.countries.length > 0) {
      this.countries.forEach(country => {
        this.countryIdMap.set(country.id, country.name_en);
        this.countryNameMap.set(country.name_en, country.id);
        if (country.name_zh) {
          this.countryNameZhMap.set(country.name_zh, country.id);
        } else {
          this.countryNameZhMap.set(country.name_en, country.id);
        }
      });
    }
    return this.countries;
  }

  async allProductPriceChanges(reload = false): Promise<ProductPriceChange[]> {
    if (this.productPriceChanges.length === 0 || reload) {
      try {
        const res = await this.webService.getAllProductPriceChanges();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          console.log("wsl res.body", res.body);
          this.productPriceChanges = (res.body as any[]).map((productPriceChange: any) => {
            return <ProductPriceChange>{
              id: productPriceChange.id,
              product_title: productPriceChange.product_title,
              barcode: productPriceChange.barcode,
              original_price: productPriceChange.original_price,
              new_price: productPriceChange.new_price,
              change_date: productPriceChange.change_date,
              created_by: productPriceChange.created_by?.name,
              created_at: productPriceChange.created_at.split('T')[0],
              shopify_id: productPriceChange.shopify_id,
              price_change_print_status: productPriceChange.price_change_print_status,
            };
          });
        } else {
          console.error('Failed to fetch product price changes', res?.status);
          this.productPriceChanges = [];
        }
      } catch (error) {
        console.error('Error fetching product price changes', error);
        this.productPriceChanges = [];
      }
    }
    return this.productPriceChanges;
  }

  async allLocations(reload = false): Promise<Location[]> {
    if (this.locations.length === 0 || reload) {
      try {
        const res = await this.webService.getAllLocations();
        if (res && res.status >= 200 && res.status < 299 && res.body) {
          this.locations = (res.body as any[]).map((location: any) => {
            return <Location>{
              id: location.id,
              name_en: location.name_en,
              name_zh: location.name_zh,
              code: location.code,
              type: LOCATION_TYPES_MAP[location.type],
              address_en: location.address_en,
              address_zh: location.address_zh,
              shopify_id: location.shopify_id,
              created_at: formatISODateToYYYYMMDD(location.created_at),
              created_by: location.created_by?.name,
              status: LOCATION_STATUS_MAP[location.status],
              shopify_inventory_collection_id: location.shopify_inventory_collection_id,
              shopify_negative_inventory_collection_id: location.shopify_negative_inventory_collection_id,
            };
          });
          this.locationEnNames = this.locations.map(location => location.name_en);
          this.locationZhNames = this.locations.map(location => location.name_zh);
          this.locationCodes = this.locations.map(location => location.code);
          this.warehouseLocation = this.locations.find(location => (location.type === '倉庫' || location.name_en.includes('Warehouse') || location.name_zh.includes('倉庫')));
          this.activeStores = this.locations.filter(location => location.type !== '倉庫' && location.status === '營業中');
          this.activeLocations = this.locations.filter(location => location.status === '營業中');
        } else {
          console.error('Failed to fetch locations', res?.status);
          this.locations = [];
        }
      } catch (error) {
        console.error('Error fetching locations', error);
        this.locations = [];
      }
    }
    return this.locations;
  }

  async allShopifyWarehouseProducts(): Promise<ShopifyProduct[]> {
    if (!this.warehouseLocation) {
      await this.allLocations();
    }
    try {
      const res = await this.webService.getInventoryDetail(this.warehouseLocation!.shopify_inventory_collection_id);
      if (res && res.status >= 200 && res.status < 299 && res.body) {
        const productsData = res.body;
        var products: ShopifyProduct[] = productsData.map((item) => {
          const firstVariant = item.variants.edges[0]?.node;

          const best_before_date = item.metafields.edges.find(
            (edge) => edge.node.key === "bbd" && edge.node.namespace === "custom"
          )?.node.value;
          const warehouseLocation = item.metafields.edges.find(
            (edge) => edge.node.key === "warehouse_location" && edge.node.namespace === "custom"
          )?.node.value;
          const buyer = item.metafields.edges.find(
            (edge) => edge.node.key === "buyer" && edge.node.namespace === "custom"
          )?.node.value;
          const purchase_date = item.metafields.edges.find(
            (edge) => edge.node.key === "purchase_date" && edge.node.namespace === "custom"
          )?.node.value;
          let standardPackQuantity = item.metafields.edges.find(
            (edge) => edge.node.key === "standard_pack_quantity" && edge.node.namespace === "custom"
          )?.node.value;
          if (standardPackQuantity === null || standardPackQuantity === undefined || standardPackQuantity === '0' || standardPackQuantity === '') {
            standardPackQuantity = item.metafields.edges.find(
              (edge) => edge.node.key === "case_size" && edge.node.namespace === "custom"
            )?.node.value;
          }

          var inventories = new Map<number, number>();
          var warehouseInventory;
          firstVariant.inventoryItem.inventoryLevels.edges.forEach((edge) => {
            const locationId = Number(edge.node.location.id.split('/').pop());
            const inventoryLevel = edge.node.available;
            inventories.set(locationId, inventoryLevel);
            if (this.warehouseLocation) {
              if (Number(locationId) === Number(this.warehouseLocation.shopify_id)) {
                warehouseInventory = inventoryLevel;
              }
            } else {
              const locationName = edge.node.location.name;
              if (locationName.includes('Warehouse') || locationName.includes('[W]')) {
                warehouseInventory = inventoryLevel;
              }
            }
          });

          if (item.productType && this.categories.length > 0) {
            var category = this.categories.find(category => category.third_level_name === item.productType.trim());
          }

          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
          const createdAtDate = new Date(item.createdAt);
          const is_new = createdAtDate > thirtyDaysAgo;

          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
          const is_transfer_new = createdAtDate > sevenDaysAgo;

          let daysSincePurchase: number | null = null;
          if (purchase_date) {
            const purchaseDate = new Date(purchase_date);
            const today = new Date();
            const timeDifference = today.getTime() - purchaseDate.getTime();
            daysSincePurchase = Math.floor(timeDifference / (1000 * 3600 * 24));
          }

          return <ShopifyProduct>{
            shopify_id: item.id.split('/').pop(),
            name: item.title,
            product_type: item.productType,
            category: category,
            first_level_name: category?.first_level_name || "",
            second_level_name: category?.second_level_name || "",
            third_level_name: category?.third_level_name || "",
            tags: item.tags,
            created_at: item.createdAt,
            is_new: is_new,
            buyer: buyer || "",
            barcode: firstVariant?.barcode || "",
            price: Number(firstVariant?.price) || 0,
            compare_at_price: Number(firstVariant?.compareAtPrice) || 0,
            cost: Number(firstVariant?.inventoryItem?.unitCost?.amount) || 0,
            best_before_date: best_before_date || "",
            best_before_date_status: this.getBestBeforeDateStatus(best_before_date),
            warehouse_location: warehouseLocation || "",
            standard_pack_quantity: Number(standardPackQuantity) || null,
            average_sales_quantity_last_seven_days: 0,
            sales_yesterday: 0,
            warehouse_inventory: warehouseInventory || 0,
            inventories: inventories,
            purchase_date: purchase_date || "",
            daysSincePurchase: daysSincePurchase,
            is_transfer_new: is_transfer_new,
          };
        });
        products = products.filter(product => product.warehouse_inventory > 0);
        products = products.sort((a, b) => b.warehouse_inventory - a.warehouse_inventory);
        return products;
      } else {
        console.error('Failed to fetch locations', res?.status);
        return [];
      }
    } catch (error) {
      console.error('Error fetching products from Shopify:', error);
      throw new Error('Failed to fetch products');
    }
    return [];
  }

  async allInventoryCollectionProducts(collection_id: string, forceRefresh = false): Promise<ShopifyProduct[]> {
    const cacheKey = `collection_${collection_id}`;
    const cachedData = this.shopifyProductsCache.get(cacheKey);
    
    // 只檢查是否有緩存和是否強制刷新
    if (cachedData && !forceRefresh) {
      console.log('Using cached products data');
      return cachedData.data;
    }
    
    try {
      const res = await this.webService.getInventoryDetail(collection_id);
      if (res?.status >= 200 && res?.status < 299 && res.body) {
        const products = this.transformProductsData(res.body);
        
        // 更新緩存，仍然保存時間戳，但不再用於判斷
        this.shopifyProductsCache.set(cacheKey, {
          data: products,
          timestamp: Date.now()
        });
        
        return products;
      }
      return [];
    } catch (error) {
      console.error('Error fetching products from Shopify:', error);
      throw new Error('Failed to fetch products');
    }
  }

  private transformProductsData(productsData: any[]): ShopifyProduct[] {
    return productsData.map((item) => {
      const firstVariant = item.variants.edges[0]?.node;

      const best_before_date = item.metafields.edges.find(
        (edge) => edge.node.key === "bbd" && edge.node.namespace === "custom"
      )?.node.value;
      const warehouseLocation = item.metafields.edges.find(
        (edge) => edge.node.key === "warehouse_location" && edge.node.namespace === "custom"
      )?.node.value;
      const buyer = item.metafields.edges.find(
        (edge) => edge.node.key === "buyer" && edge.node.namespace === "custom"
      )?.node.value;
      const purchase_date = item.metafields.edges.find(
        (edge) => edge.node.key === "purchase_date" && edge.node.namespace === "custom"
      )?.node.value;
      let standardPackQuantity = item.metafields.edges.find(
        (edge) => edge.node.key === "standard_pack_quantity" && edge.node.namespace === "custom"
      )?.node.value;
      if (standardPackQuantity === null || standardPackQuantity === undefined || standardPackQuantity === '0' || standardPackQuantity === '') {
        standardPackQuantity = item.metafields.edges.find(
          (edge) => edge.node.key === "case_size" && edge.node.namespace === "custom"
        )?.node.value;
      }

      var inventories = new Map<number, number>();
      var warehouseInventory;
      firstVariant.inventoryItem.inventoryLevels.edges.forEach((edge) => {
        const locationId = Number(edge.node.location.id.split('/').pop());
        const inventoryLevel = edge.node.available;
        inventories.set(locationId, inventoryLevel);
        if (this.warehouseLocation) {
          if (Number(locationId) === Number(this.warehouseLocation.shopify_id)) {
            warehouseInventory = inventoryLevel;
          }
        } else {
          const locationName = edge.node.location.name;
          if (locationName.includes('Warehouse') || locationName.includes('[W]')) {
            warehouseInventory = inventoryLevel;
          }
        }
      });

      var category;
      if (item.productType && item.productType.trim() !== '') {
        if (!this.categories || this.categories.length === 0) {
          this.allCategories().then(() => {
            category = this.categories.find(category => category.third_level_name === item.productType.trim());
          });
        } else {
          category = this.categories.find(category => category.third_level_name === item.productType.trim());
        }
      }

      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const createdAtDate = new Date(item.createdAt);
      const is_new = createdAtDate > thirtyDaysAgo;

      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const is_transfer_new = createdAtDate > sevenDaysAgo;

      let daysSincePurchase: number | null = null;
      if (purchase_date) {
        const purchaseDate = new Date(purchase_date);
        const today = new Date();
        const timeDifference = today.getTime() - purchaseDate.getTime();
        daysSincePurchase = Math.floor(timeDifference / (1000 * 3600 * 24));
      }

      return <ShopifyProduct>{
        shopify_id: item.id.split('/').pop(),
        name: item.title,
        barcode: firstVariant?.barcode || "",
        product_type: item.productType,
        category: category,
        first_level_name: category?.first_level_name || "",
        second_level_name: category?.second_level_name || "",
        third_level_name: category?.third_level_name || "",
        tags: item.tags,
        created_at: item.createdAt,
        is_new: is_new,
        buyer: buyer || "",
        price: Number(firstVariant?.price) || 0,
        compare_at_price: Number(firstVariant?.compareAtPrice) || 0,
        cost: Number(firstVariant?.inventoryItem?.unitCost?.amount) || 0,
        best_before_date: best_before_date || "",
        best_before_date_status: this.getBestBeforeDateStatus(best_before_date),
        warehouse_location: warehouseLocation || "",
        standard_pack_quantity: Number(standardPackQuantity) || null,
        average_sales_quantity_last_seven_days: 0,
        sales_yesterday: 0,
        warehouse_inventory: warehouseInventory || 0,
        inventories: inventories,
        purchase_date: purchase_date || "",
        daysSincePurchase: daysSincePurchase,
        is_transfer_new: is_transfer_new,
      };
    });
  }

  getCategoryByProductType(product_type: string | null): Category | undefined {
    if (!product_type) {
      return undefined;
    }
    if (!this.categories || this.categories.length === 0) {
      this.allCategories().then((categories) => {
        this.categories = categories;
        return this.categories.find(category => category.third_level_name === product_type.trim());
      });
    } else {
      return this.categories.find(category => category.third_level_name === product_type.trim());
    }
    return undefined;
  }


  locationEnNameExists(name: string): boolean {
    return this.locationEnNames.includes(name);
  }

  locationZhNameExists(name: string): boolean {
    return this.locationZhNames.includes(name);
  }

  locationCodeExists(code: string): boolean {
    return this.locationCodes.includes(code);
  }

  clearLocationCache() {
    this.locations = [];
    this.locationEnNames = [];
    this.locationZhNames = [];
    this.locationCodes = [];
  }


  // clearProductCache() {
  //   this.products = [];
  //   this.productNames = [];
  //   this.productBarcodes = [];
  // }

  // clearSupplierCache() {
  //   this.suppliers = [];
  //   this.supplierNamesEn = [];
  //   this.supplierNamesZh = [];
  //   this.supplierCompanyNamesEn = [];
  //   this.supplierCompanyNamesZh = [];
  // }

  clearCategoryCache() {
    this.categories = [];
    this.firstLevelCategoryNames = [];
    this.secondLevelCategoryNames = [];
    this.thirdLevelCategoryNames = [];
    this.distributionCategoryNames = [];
    this.categoryOptions = [];
  }

  // clearBrandCache() {
  //   this.brands = [];
  //   this.brandNames = [];
  // }

  clearUserCache() {
    this.users = [];
    this.userNames = [];
  }

  clearCountryCache() {
    this.countries = [];
    this.countryIdMap = new Map<number, string>();
    this.countryNameMap = new Map<string, number>();
    this.countryNameZhMap = new Map<string, number>();
  }

  getBestBeforeDateStatus(best_before_date: string | undefined): string {
    if (!best_before_date) {
      return '';
    }
    const best_before_date_date = new Date(best_before_date);
    const today = new Date();
    const diffTime = best_before_date_date.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < -90) {
      return '<-90';
    }
    if (diffDays < 0) {
      return '0';
    }
    if (diffDays <= 7) {
      return '<7';
    }
    if (diffDays <= 31) {
      return '<31';
    }
    return '>31';
  }

  clearProductsCache() {
    this.shopifyProductsCache.clear();
  }

}

