<nz-spin [nzSpinning]="isSpinning">
  <nz-breadcrumb style="margin-bottom: 16px;">
    <!-- <nz-breadcrumb-item>admin</nz-breadcrumb-item> -->
    <nz-breadcrumb-item>採購單管理</nz-breadcrumb-item>
    <nz-breadcrumb-item>修改採購單</nz-breadcrumb-item>
  </nz-breadcrumb>

  <nz-page-header class="site-page-header" nzTitle="修改採購單" nzContent
    style="height: 44px; padding-bottom: 54px; padding-top: 0; padding-left: 4px; padding-right: 0px;">
  </nz-page-header>

  <nz-card>
    <form nz-form [formGroup]="updatePurchaseOrderForm" (ngSubmit)="onSubmit()">

      <nz-card nzTitle="基本資料" [nzBordered]="true">

        <div nz-row>
          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
            <nz-form-label nzRequired nzSpan="24">供應商</nz-form-label>
            <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26">
              <nz-select nzMode="default" formControlName="supplier_id" nzPlaceHolder="選擇供應商" nzAllowClear nzShowSearch
                nzServerSearch>
                <nz-option *ngFor="let supplier of supplierList" [nzValue]="supplier.id"
                  [nzLabel]="supplier.name_en"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
            <nz-form-label nzRequired nzSpan="24">採購員</nz-form-label>
            <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26">
              <nz-select nzMode="default" formControlName="buyer_id" nzPlaceHolder="選擇採購員" nzAllowClear nzShowSearch
                nzServerSearch>
                <nz-option *ngFor="let buyer of buyerList" [nzValue]="buyer.id" [nzLabel]="buyer.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
            <nz-form-label nzSpan="24">貨幣類型</nz-form-label>
            <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26" nzErrorTip="輸入貨幣類型">
              <nz-select formControlName="currency" placeholder="请选择貨幣類型" required>
                <nz-option nzValue="HKD" nzLabel="港幣"></nz-option>
                <nz-option nzValue="RMB" nzLabel="人民幣"></nz-option>
                <nz-option nzValue="TWD" nzLabel="新臺幣"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-row>
          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
            <nz-form-label nzSpan="24">採購日期</nz-form-label>
            <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26">
              <nz-date-picker formControlName="purchase_date"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
            <nz-form-label nzSpan="24">到貨日期</nz-form-label>
            <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26">
              <nz-date-picker formControlName="expected_arrival_date"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
            <nz-form-label nzSpan="24">備註</nz-form-label>
            <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26">
              <textarea nz-input formControlName="remarks" placeholder="輸入備註" nzAutosize></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="8">
          <nz-form-label nzRequired nzSpan="24">到貨地點</nz-form-label>
          <nz-form-control [nzLg]="26" [nzMd]="20" [nzSm]="20" [nzXl]="26" [nzXXl]="26">
            <nz-select 
      formControlName="arrival_location_id"  
      nzPlaceHolder="请选择到货地点">
      <nz-option 
        *ngFor="let location of cacheService.activeLocations"
        [nzLabel]="location.code + ' ' + location.name_zh + (location.type == 'store' ? '店' : '')" 
        [nzValue]="location.id">  <!-- 修改这里，直接使用 location.id 作为值 -->
      </nz-option>
    </nz-select>
          </nz-form-control>
        </nz-form-item>
          
          
        </div>
      </nz-card>

      <nz-card nzTitle="產品資料" [nzBordered]="true">
        <div style="overflow: auto;">
          <div class="table-fixed-header">
            <form (ngSubmit)="onSubmit()" [formGroup]="updatePurchaseOrderForm">
              <div formArrayName="purchaseOrderProducts">
                <div *ngFor="let product of products.controls; let i = index" [formGroupName]="i">
                  <nz-row class="product-card-form-item" nzJustify="space-between" nzAlign="middle">
                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="6">
                      <nz-form-label nzRequired>產品選擇</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <nz-select formControlName="product_id" nzShowSearch nzAllowClear nzPlaceHolder="選擇產品"
                          class="uniform-size" [nzFilterOption]="productFilterOption">
                          <nz-option *ngFor="let product of filteredProductList" [nzValue]="product.id"
                            [nzLabel]="product.name"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="4">
                      <nz-form-label nzRequired>採購成本</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <nz-input-group nzSuffix="HKD" nzPrefix="$">
                          <input nz-input formControlName="cost">
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="4">
                      <nz-form-label nzRequired>售價</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <nz-input-group nzSuffix="HKD" nzPrefix="$">
                          <input nz-input formControlName="selling_price">
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="4">
                      <nz-form-label nzRequired>市場價格</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <nz-input-group nzSuffix="HKD" nzPrefix="$">
                          <input nz-input formControlName="market_price">
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="4">
                      <nz-form-label nzRequired>採購數量</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <nz-input-number formControlName="quantity" style="width: 100%;"></nz-input-number>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="6">
                      <nz-form-label>最佳賞味期</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <nz-date-picker formControlName="best_before_date" style="width: 100%;"></nz-date-picker>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="10" [nzLg]="6">
                      <nz-form-label>促銷信息</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <input nz-input formControlName="promotion" placeholder="$10/3件">
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item nz-col [nzXs]="24" [nzSm]="24" [nzMd]="10" [nzLg]="6">
                      <nz-form-label>採購原因</nz-form-label>
                      <nz-form-control [nzSpan]="22">
                        <textarea nz-input rows="1" formControlName="purchase_reason"></textarea>
                      </nz-form-control>
                    </nz-form-item>
                    <button nz-col nz-button type="button" nzType="primary" [nzMd]="4" [nzLg]="4" [nzXXl]="2"
                      [disabled]="products.length === 1" (click)="removeRow(i)">删除商品</button>
                  </nz-row>
                  <button *ngIf="i === (products.controls.length - 1)" nz-button nzType="primary" (click)="addRow()"
                    type="button">新增商品</button>
                  <nz-divider></nz-divider>
                </div>
              </div>
            </form>
          </div>
        </div>
        <nz-form-control nz-col nzSpan="24" nzOffset="1">
        </nz-form-control>
      </nz-card>



      <nz-card nzTitle="文件上傳" [nzBordered]="true" style="padding-bottom: 120px;">
        <p>發票圖片</p>
        <nz-upload
          [nzListType]="'picture-card'"
          [(nzFileList)]="invoiceFiles"
          [nzMultiple]="true"
          [nzLimit]="5"
          [nzCustomRequest]="getCustomUploadRequest('invoice')"
          [nzPreview]="handlePreview"
          [nzDownload]="handleDownload"
          [nzRemove]="handleRemove('invoice')"
          [nzShowUploadList]="{ showPreviewIcon: true, showDownloadIcon: true, showRemoveIcon: true }"
        >
          <div>
            <span nz-icon nzType="plus"></span>
          </div>
        </nz-upload>
        <p>採購單文件</p>
        <nz-upload
          [nzListType]="'picture-card'"
          [(nzFileList)]="othersFiles"
          [nzMultiple]="true"
          [nzLimit]="5"
          [nzCustomRequest]="getCustomUploadRequest('others')"
          [nzPreview]="handlePreview"
          [nzDownload]="handleDownload"
          [nzRemove]="handleRemove('others')"
          [nzShowUploadList]="{ showPreviewIcon: true, showDownloadIcon: true, showRemoveIcon: true }"
        >
          <div>
            <span nz-icon nzType="plus"></span>
          </div>
        </nz-upload>
      </nz-card>

    </form>
  </nz-card>

  <nz-footer>
    <div class="footer-bar flex justify-content-end">
      <div>
        <button nz-button [nzType]="'primary'" (click)="onSubmit()" style="margin-right: 24px;">提交</button>
        <button nz-button [nzType]="'default'" (click)="cancel() ">返回</button>
      </div>
    </div>
  </nz-footer>
</nz-spin>

<nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
  (nzOnCancel)="previewVisible = false">
  <ng-template #modalContent>
    <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
  </ng-template>
</nz-modal>