// src/app/auth.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PurchaseOrder } from '../models/purchaseOrder';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  constructor(private http: HttpClient) {}

  get headers(): HttpHeaders {
    return this.getHttpHeader();
  }

  getHttpHeader() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', 'Bearer Vq26dbQ9oQbZfCVIjwCHqBqYP47z4flZrv3sAbM75u7MZxta7YFr9PmC8qegfZX1');

    return headers;
  }

  async getPurchaseOrder(id: number): Promise<PurchaseOrder> {
    const api_url = `${environment.api_endpoint}purchase-orders/${id}`;
    return this.http.get(api_url, { headers: this.headers }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async review(reviewPurchaseOrderDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}purchase-orders/review`;
    try {
      const res = await this.http.put(api_url, reviewPurchaseOrderDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async payment(paymentPurchaseOrderDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}purchase-orders/payment`;
    try {
      const res = await this.http.put(api_url, paymentPurchaseOrderDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }
}