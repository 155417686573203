//local imports
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, ObjectUnsubscribedError, Observable, Subscription, tap } from 'rxjs';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray, } from '@angular/forms';
import { StateService } from 'src/app/services/state.service';
import { WebService } from 'src/app/services/web.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PurchaseOrderService } from 'src/app/services/purchase_order.service';
import { CacheService } from 'src/app/services/cache.service';

//ng-zorro imports
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PurchaseOrder, Supplier, User, Product } from 'src/app/models/purchaseOrder';
import { RowSettings } from 'src/app/models/row-setting';
import { formatISODateToYYYYMMDD, isNotEmpty } from 'src/app/utils/utils';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ViewEncapsulation } from '@angular/core';
import { NzTableSize } from 'ng-zorro-antd/table';
import { NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ProductImage } from 'src/app/models/productImage';
import { ProductSpecification } from 'src/app/models/productSpecification';
import { PurchaseOrderProduct, PurchaseOrderFile } from 'src/app/models/purchaseOrder';
import { PermissionService } from 'src/app/services/permission.service';
import { SupplierFile } from 'src/app/models/supplier-file';


const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-update-purchaseOrders',
  templateUrl: './update-purchase-orders.component.html',
  styleUrls: ['./update-purchase-orders.component.scss']
})
export class UpdatePurchaseOrdersComponent implements OnInit {
  updatePurchaseOrderForm: FormGroup;
  invoice_url: string = '';
  file_urls: string[] = [];
  invoiceFiles: NzUploadFile[] = [];
  othersFiles: NzUploadFile[] = [];

  newFiles: PurchaseOrderFile[] = [];
  deletedFiles: NzUploadFile[] = [];

  purchaseOrderId: number | undefined;
  current_user: User = this.StateService.user;
  fileList: NzUploadFile[] = [];
  purchaseOrder: PurchaseOrder = {} as PurchaseOrder;
  isLoading = true;
  editCache: { [key: number]: any } = {};

  isSpinning = false;
  searchBarcode: string = '';
  searchTerm: string = '';
  listOfProduct: PurchaseOrderProduct[] = [];
  listOfFile: PurchaseOrderFile[] = [];
  buyerList: User[] = [];
  productList: Product[] = [];
  filteredProductList: Product[] = [];
  supplierList: Supplier[] = [];

  previewImage: string | undefined = '';
  previewVisible = false;

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    console.log("preview file", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  handleDownload(file: NzUploadFile): void {
    console.log("download file", file);
    if (file.url) {
      window.open(file.url, '_blank');
    } else {
      console.error('文件没有 URL,无法下载');
    }
  }

  constructor(
    public cacheService: CacheService,
    private message: NzMessageService,
    private StateService: StateService,
    private router: Router,
    private purchaseOrderService: PurchaseOrderService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private webService: WebService,
    public permission: PermissionService
  ) {
    this.permission.check(this.permission.isProductSectionAllowed);

    this.updatePurchaseOrderForm = this.fb.group({
      supplier_id: ['', [Validators.required]],
      buyer_id: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      purchase_date: [''],
      expected_arrival_date: [''],
      remarks: [''],
      arrival_location_id: ['', [Validators.required]],
      purchaseOrderProducts: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.isSpinning = true;
    this.purchaseOrderId = +(this.route.snapshot.paramMap.get('id') ?? '');
    this.StateService.user = this.StateService.getCurrentUser();

    if (!this.StateService.user) {
      console.error('User is not logged in');
      this.router.navigate(['/purchaseOrders']);
    }

    this.cacheService.allSuppliers().then((res) => {
      this.supplierList = res;
    });

    this.cacheService.allUsers().then((res) => {
      this.buyerList = res;
    });

    this.cacheService.allProducts().then((res) => {
      this.productList = res;
      this.filteredProductList = res;
    });
     this.updatePurchaseOrderForm.get('arrival_location_id')?.valueChanges.subscribe(() => {
      this.updatePurchaseOrderForm.get('expected_arrival_date')?.updateValueAndValidity();
    });
     this.cacheService.allLocations(true).then(() => {
    
    
    if (this.cacheService.activeLocations?.length > 0) {
      // 查找第一個類型為倉庫的位置
      const defaultWarehouse = this.cacheService.activeLocations.find(
        location => location.type === '倉庫' || 
                   location.type === 'warehouse' ||
                   location.name_zh.includes('倉庫')
      );
      
      if (defaultWarehouse) {
        setTimeout(() => {
          this.updatePurchaseOrderForm.patchValue({
            arrival_location_id: defaultWarehouse.id
          });
        });
      }
    }
  }).catch(error => {
    console.error('Error loading locations:', error);
  });

    this.purchaseOrderService.getPurchaseOrder(this.purchaseOrderId).then((res) => {
      if (!res) {
        this.router.navigate(['/purchase-orders']);
        return;
      }
      this.purchaseOrder = res;
      this.updatePurchaseOrderForm.patchValue({
        supplier_id: res.supplier.id,
        buyer_id: res.buyer.id,
        currency: res.currency,
        purchase_date: new Date(res.purchase_date).toISOString().split('T')[0],
        expected_arrival_date: res.expected_arrival_date,
        remarks: res.remarks,
        // 添加这一行，设置已有的 arrival_location_id
        arrival_location_id: res.arrival_location?.id
      });
      this.listOfProduct = res.purchaseOrderProducts;
      this.listOfProduct.forEach((poProduct) => {
        this.products.push(
          this.fb.group({
            product_id: [poProduct.product.id, [Validators.required]],
            cost: [poProduct.cost, [Validators.required]],
            selling_price: [poProduct.selling_price, [Validators.required]],
            market_price: [poProduct.market_price, [Validators.required]],
            quantity: [poProduct.quantity, [Validators.required]],
            best_before_date: [poProduct.best_before_date],
            promotion: [poProduct.promotion],
            purchase_reason: [poProduct.purchase_reason],
          })
        );
      });
      this.loadPurchaseOrderFiles();
      this.isSpinning = false;
    });
  }

  async loadPurchaseOrderFiles() {
    try {
      const fileLoadPromises = this.purchaseOrder.purchaseOrderFiles.map(async (file) => {
        const url = await this.webService.getPurchaseOrderFileSignedUrl(file.storage_filename);
        const newFile: NzUploadFile = {
          id: file.id,
          uid: file.storage_filename,
          name: file.filename,
          filename: file.filename,
          status: 'done',
          url: url,
          thumbUrl: url
        };
        return { fileType: file.file_type, file: newFile };
      });

      const loadedFiles = await Promise.all(fileLoadPromises);

      const invoices: NzUploadFile[] = [];
      const others: NzUploadFile[] = [];

      loadedFiles.forEach(({ fileType, file }) => {
        if (fileType === 'invoice') {
          invoices.push(file);
        } else if (fileType === 'others') {
          others.push(file);
        }
      });

      // Assign new arrays to trigger change detection
      this.invoiceFiles = invoices;
      this.othersFiles = others;

    } catch (error) {
      console.error('Error loading supplier files', error);
    }
  }

  get products(): FormArray {
    return this.updatePurchaseOrderForm.controls["purchaseOrderProducts"] as FormArray;
  }

  addRow(): void {
    this.products.push(
      this.fb.group({
        product_id: [null, [Validators.required]],
        cost: [null, [Validators.required]],
        selling_price: [null, [Validators.required]],
        market_price: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        best_before_date: [null],
        promotion: [null],
        purchase_reason: [null],
    }));
  }

  removeRow(index: number): void {
    this.products.removeAt(index);
  }

  productFilterOption = (input: string, option: any): boolean => {
    return option.nzLabel.toLowerCase().includes(input.toLowerCase()) ||
      this.productList.find(product => product.id === option.nzValue)?.barcode.toLowerCase().includes(input.toLowerCase());
  };

  filterProducts(): void {
    if (!this.searchTerm) {
      this.filteredProductList = this.productList;
    } else {
      this.filteredProductList = this.productList.filter(product =>
        product.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        product.barcode.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  getCustomUploadRequest(fileType: string) {
    return (item: NzUploadXHRArgs) => {
      const sub = this.customUploadRequest(item, fileType).subscribe({
        error: (err) => {
          console.error('Upload error:', err);
          item.onError!(err, item.file);
        }
      });
      return sub;
    };
  }

  customUploadRequest = (item: NzUploadXHRArgs, fileType: string): Observable<any> => {
    const formData = new FormData();
    formData.append('file', item.file as any);
    const uploadUrl = this.webService.getPurchaseOrderUploadUrl();
  
    return this.http.post(uploadUrl, formData, {
      headers: this.webService.getHttpHeader(true),
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = event.loaded / event.total! * 100;
          }
          item.onProgress!(event, item.file);
        } else if (event instanceof HttpResponse) {
          if (event.body && event.body.location && event.body.filename) {
            this.updateFileList(fileType, event.body.location, event.body.filename, item.file.name);
            item.onSuccess!(event.body, item.file, event);
          } else {
            item.onError!('Response does not contain a valid URL', item.file);
          }
        }
      }),
      finalize(() => {
        // This will be called when the upload is complete or if there's an error
      })
    );
  }

  updateFileList(fileType: string, url: string, filename: string, fileOriginalName: string) {
    this.newFiles.push(<PurchaseOrderFile>{
      purchase_order_id: this.purchaseOrder.id,
      filename: fileOriginalName,
      storage_filename: filename,
      file_type: fileType,
      url: url
    });
    const newFile: NzUploadFile = {
      uid: `-${Date.now()}`,
      name: fileOriginalName,
      filename: filename,
      status: 'done',
      message: '上傳成功',
      url: url,
      thumbUrl: url
    };

    switch (fileType) {
      case 'invoice':
        this.invoiceFiles = [...this.invoiceFiles, newFile];
        this.invoiceFiles = this.invoiceFiles.filter((file) => file.filename !== undefined);
        this.updatePurchaseOrderForm.patchValue({ invoice_file: this.invoiceFiles.map(f => f.url).join(',') });
        break;
      case 'others':
        this.othersFiles = [...this.othersFiles, newFile];
        this.othersFiles = this.othersFiles.filter((file) => file.filename !== undefined);
        this.updatePurchaseOrderForm.patchValue({ others_file: this.othersFiles.map(f => f.url).join(',') });
        break;
    }
  }

  handleRemove = (fileType: string) => (file: NzUploadFile): boolean => {
    this.deletedFiles.push(file);
    const fileList = this.getFileList(fileType);
    const index = fileList.findIndex(f => f.uid === file.uid);
    if (index > -1) {
      fileList.splice(index, 1);
    }
    return true;
  }

  private getFileList(fileType: string): NzUploadFile[] {
    switch (fileType) {
      case 'invoice':
        return this.invoiceFiles;
      case 'others':
        return this.othersFiles;
      default:
        return [];
    }
  }

  async onSubmit() {
    console.log("Form Values:", this.updatePurchaseOrderForm.value); // 添加这行来调试
    
    // console.log("374form", this.updatePurchaseOrderForm);
    

    if (this.updatePurchaseOrderForm.status === 'INVALID') {
      Object.values(this.updatePurchaseOrderForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      Object.values(this.products.controls).forEach((product: any) => {
        if (product.invalid) {
          Object.values(product.controls).forEach((control: any) => {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          });
        }
      });
      return;
    }
    // send update request
    this.isSpinning = true;
    const currentUser = this.StateService.user;
    if (!currentUser) {
      console.error('User is not logged in');
      this.router.navigate(['/purchaseOrders']);
    }
    if (!this.purchaseOrderId) {
      console.error('Purchase Order ID is not set');
      this.router.navigate(['/purchaseOrders']);
      return;
    }

    const purchaseOrderData = this.updatePurchaseOrderForm.value;
    purchaseOrderData.invoice_url = this.invoice_url;
    purchaseOrderData.purchaseOrderFiles = this.file_urls;

    console.log("request",purchaseOrderData);
    this.webService.updatePurchaseOrders(this.purchaseOrderId, currentUser.id, purchaseOrderData).then(
      response => {
        this.isSpinning = false;
        this.newFiles.forEach(file => {
          this.webService.addPurchaseOrderFile(file);
        });
        this.deletedFiles.forEach(file => {
          this.webService.deletePurchaseOrderFile(file.id);
        });
        this.message.success('PurcahseOrder updated successfully');
        this.router.navigate(['/purchase-orders']);
      }
    ).catch(
      error => {
        this.message.error('Failed to update Product');
      }
    );
  }

  cancel() {
    this.router.navigate(['/purchase-orders']);
  }
}
